import React, { useState } from "react"
import { Helmet } from "react-helmet"

import { AnchorLink } from "gatsby-plugin-anchor-links"

import MenuItems from "./MenuItems"

import "../styles/main.css"
import logo from "../images/logo.png"
import logo_footer from "../images/logo_rodape.png"

export default function Layout({ children, isHome = false }) {
    const [isMenuActive, setIsMenuActive] = useState(false);

    return (
        <>
            <Helmet>
                <title>Engajados - Instituto de Tecnologias Colaborativas</title>
                <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
            </Helmet>

            <header id="header">
                <div className="image leftlogo">
                    <AnchorLink to={isHome ? '/#banner' : '/'}><img src={logo} alt="logo imagem" /></AnchorLink>
                </div>

                <AnchorLink to={isHome ? '/#banner' : '/'}></AnchorLink>

                <nav>
                    <div id="menuhorizontal">
                        <ul className="links2">
                            <MenuItems isHome={isHome} />
                        </ul>
                    </div>
                    <a href="#menu" onClick={(e)=>{e.preventDefault(); setIsMenuActive(true);}}></a>
                </nav>
            </header>

            {/* NAV lista links */}
            <nav id="menu" className={`${isMenuActive ? 'active' :''}`}>
                <ul className="links">
                    <MenuItems isHome={isHome} onClick={()=>setIsMenuActive(false)} />
                </ul>
                <a href="#menu" className="close" onClick={(e)=>{e.preventDefault(); setIsMenuActive(false);}}></a>
            </nav>

            <main>{children}</main>

            {/* FOOTER ou Rodapé */}
            <footer id="footer">
                <div className="inner">
                    <div className="content">
                        <section>
                            <img
                                src={logo_footer}
                                width="60%"
                                height= "100%"
                                float="center "
                                alt="logo footer"
                            />
                        </section>

                        {/* <section>
                            <h4>Encontrou o que você buscava?</h4>
                            <ul className="alt">
                                <li><a href="#">O que é a Engajados?</a></li>
                                <li><a href="#">Como construimos soluções?</a></li>
                                <li>
                                    <a href="#"
                                    >Quais projetos já adotam nossas ferramentas colaborativas?</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                    >Como trazer Engajados para sua iniciativa, projeto ou empresa?</a>
                                </li>
                            </ul>
                        </section> */}

                        <section>
                            <h4>Acompanhe-nos na jornada:</h4>
                            <ul className="plain">
                                <li>
                                    <a href="https://www.facebook.com/Engajados/"
                                    ><i className="icon fa-facebook"></i>Facebook</a
                                    >
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/engajados/"
                                    ><i className="icon fa-linkedin"></i>LinkedIn</a
                                    >
                                </li>
                                {/* <hr />
                                <h4>Receba nossa newsletter!</h4>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value=""
                                    placeholder="seu e-mail"
                                    onChange={console.log}
                                /><br />
                                <a href="#" className="button primary small">Enviar!</a> */}
                            </ul>
                        </section>
                    </div>
                    <div className="copyright">&copy; Engajados 2021</div>
                </div>
            </footer>

        </>
    )
}