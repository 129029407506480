import React from 'react'

import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function MenuItems({ isHome, onClick = () => { } }) { 
    return (
        <>
            <li>{<AnchorLink onAnchorLinkClick={onClick} to={isHome ? '/#banner' : '/'}>HOME</AnchorLink>}</li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#main" className="anchor_link">SOBRE</AnchorLink></li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#cta2" className="anchor_link">METODOLOGIA</AnchorLink></li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#cta" className="anchor_link">DOROTHY</AnchorLink></li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#pj" className="anchor_link">PROJETOS</AnchorLink></li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#dp" className="anchor_link">DEPOIMENTOS</AnchorLink></li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#time" className="anchor_link">EQUIPE</AnchorLink></li>
            <li><AnchorLink onAnchorLinkClick={onClick} to="/#serv" className="anchor_link">CONTATO</AnchorLink></li>
            
            {/* <li><a href="https://www.facebook.com/Engajados/"><i class="icon fa-facebook"></i></a></li>
            <li><a href="https://www.linkedin.com/company/engajados/"><i class="icon fa-linkedin"></i></a></li> */}
        </>
    );
}